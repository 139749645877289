import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import { useDispatch, useSelector } from "react-redux"
import { setPage } from "../redux/reducers/indexSlice"
import { RootState } from "../redux/store"
import messages from "../assets/messages.json"
import { graphql } from "gatsby"

import { useForm } from "react-hook-form"
import Axios from "axios"
import { Fade, Slide } from "react-reveal"

const Contatti: React.FC<any> = ({ data }) => {
    const language = useSelector((state: RootState) => state.inner.language)
    const dispatch = useDispatch()
    const { register, handleSubmit, formState } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })

    useEffect(() => {
        dispatch(setPage(4))
    }, [])

    const submit = (values) => {
        const payload = {
            ...values,
            webform_id: 'contact'
        }
        delete payload.privacy

        Axios({
            method: 'POST',
            url: '/webform_rest/submit?_format=json',
            data: payload,
            baseURL: 'http://backend-dandyhome.dotcom.ts.it',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf8',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-origin'
            }
        }).then(res => {
            console.log(res)
        })
    }

    return (
        <Layout>
            <SEO title={data.drupal[language.toLowerCase()].title} />
            <Banner
                title={data.drupal[language.toLowerCase()].title}
                image={data.drupal[language.toLowerCase()].fieldImg}
            />
            <div className="c-paragraph c-paragraph__contact u-txtA(l) l-flx(aic)">
                <Fade bottom>
                    <h2 className="rellax" data-rellax-xs-speed="-0.2" data-rellax-mobile-speed="-0.5">
                        {data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].via}
                        <br></br>
                        {data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].ccp}
                    </h2>
                </Fade>
                <div>
                    <Fade bottom>
                        <div>
                            {/* <h5>{messages[language.toLowerCase()].contactUsDescr}</h5> */}
                            <p>TEL: {data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].tel}</p>
                            <p>EMAIL: {data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].email}</p>
                        </div>
                    </Fade>
                    {/* <div>
                        <p>
                            <span>{messages[language.toLowerCase()].monday}</span>
                            <span>{data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].lun ? data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].lun : messages[language.toLowerCase()].close}</span>
                        </p>
                        <p>
                            <span>{messages[language.toLowerCase()].tuesday}</span>
                            <span>{data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].mar ? data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].mar : messages[language.toLowerCase()].close}</span>
                        </p>
                        <p>
                            <span>{messages[language.toLowerCase()].wednesday}</span>
                            <span>{data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].mer ? data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].mer : messages[language.toLowerCase()].close}</span>
                        </p>
                        <p>
                            <span>{messages[language.toLowerCase()].thursday}</span>
                            <span>{data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].gio ? data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].gio : messages[language.toLowerCase()].close}</span>
                        </p>
                        <p>
                            <span>{messages[language.toLowerCase()].friday}</span>
                            <span>{data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].ven ? data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].ven : messages[language.toLowerCase()].close}</span>
                        </p>
                        <p>
                            <span>{messages[language.toLowerCase()].saturday}</span>
                            <span>{data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].sab ? data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].sab : messages[language.toLowerCase()].close}</span>
                        </p>
                        <p>
                            <span>{messages[language.toLowerCase()].sunday}</span>
                            <span>{data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].dom ? data['customAdminOrariAdminOrariResource' + (language === 'IT' ? 'It' : 'En')].dom : messages[language.toLowerCase()].close}</span>
                        </p>
                    </div> */}
                </div>
            </div>
            <Slide left>
                <div>
                    <iframe width="100%" height="500" src="https://maps.google.com/maps?q=via%20roma%201%2C%20trieste&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}></iframe>
                </div>
            </Slide>
            <div className="c-contactForm">
                <Fade bottom>
                    <div>
                        <h2>
                            O scrivici dal form qui sotto
                        </h2>
                        <p>
                            Vieni a trovarci nel nostro showroom o contattaci ai recapiti indicati sopra, oppure scrivici direttamente utlizzando il form qui sotto. Ci metteremo in contatto con te il prima possibile per rispondere alle tue domande!
                        </p>
                    </div>
                </Fade>
                <Fade bottom>
                    <form onSubmit={handleSubmit(submit)}>
                        <label htmlFor="name">
                            <input type="text" name="name" id="name" ref={register({ required: true })} placeholder={messages[language.toLowerCase()].name} />
                        </label>
                        <label htmlFor="surname">
                            <input type="text" name="surname" id="surname" ref={register({ required: true })} placeholder={messages[language.toLowerCase()].surname} />
                        </label>
                        <label htmlFor="tel">
                            <input type="tel" name="tel" id="tel" ref={register({ required: true })} placeholder={messages[language.toLowerCase()].phone} />
                        </label>
                        <label htmlFor="email">
                            <input type="email" name="email" id="email" ref={register({ required: true })} placeholder={messages[language.toLowerCase()].email} />
                        </label>
                        <label htmlFor="message">
                            <textarea name="message" id="message" ref={register({ required: true })} placeholder={messages[language.toLowerCase()].message} rows={1}></textarea>
                        </label>
                        <div>
                            <label htmlFor="privacy">
                                <input type="checkbox" id="privacy" name="privacy" ref={register({ required: true })} />
                            </label>
                            <div>
                                <p>{messages[language.toLowerCase()].privacy}</p>
                                <a className="o-btn(arrowRight)">{messages[language.toLowerCase()].readPrivacy}</a>
                            </div>
                        </div>
                        <button className="o-btn(angled)" disabled={!formState.isValid}>{messages[language.toLowerCase()].send}</button>
                    </form>
                </Fade>
            </div>
        </Layout>
    )
}

export default Contatti

export const query = graphql`
query {
    customAdminOrariAdminOrariResourceIt {
        dom
        gio
        lun
        mar
        mer
        sab
        ven
    }
    customAdminOrariAdminOrariResourceEn {
        dom
        gio
        lun
        mar
        mer
        sab
        ven
    }
    customAdminContactsAdminContactsResourceEn {
        ccp
        email
        via
        tel
    }
    customAdminContactsAdminContactsResourceIt {
        ccp
        email
        tel
        via
    }
    drupal {
        it: nodeById(id: "24", language: IT) {
            ... on Drupal_NodePaginaBase {
            nid
            fieldImg {
                alt
                url
            }
            title
            }
        }
        en: nodeById(id: "24", language: EN) {
            ... on Drupal_NodePaginaBase {
            nid
            fieldImg {
                alt
                url
            }
            title
            }
        }
    }
}
`